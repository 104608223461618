import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ContentContainer from '../../../content-container';
import { Typography } from '@material-ui/core';
import Media from '../../../media';
import RenderHtml from '../../../../helpers/render-html';
import SocialLinks from './_components/social-links';

const useStyles = makeStyles(theme => ({
  footer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    marginTop: 50,
    flexShrink: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  copyRights: {
    color: 'rgba(0, 0, 0, 0.8)',
    opacity: 0.8,
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      height: 'auto',
      width: 100,
      [theme.breakpoints.up('lg')]: {
        width: 120,
      },
      [theme.breakpoints.up('xl')]: {
        width: 160,
      },
    },
  },
  header: {
    textAlign: 'center',
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          contentfulFooter(contentful_id: { eq: "12U9MWHEz79SoQv8pjPrwh" }) {
            logo {
              ...AssetUrl
            }
            body {
              childMarkdownRemark {
                html
              }
            }
            socialButtons {
              ...SocialBlock
            }
          }
        }
      `}
      render={({ contentfulFooter: footer }) => (
        <footer className={classes.footer}>
          <ContentContainer className={classes.container}>
            <div className={classes.logo}>
              <Link to='/'>
                <Media alt={'Logo Lider Jasło'} source={footer.logo} />
              </Link>
              {footer.socialButtons && <SocialLinks buttons={footer.socialButtons} />}
            </div>
            {footer.header && (
              <Typography variant='h3' className={classes.header}>
                {footer.header}
              </Typography>
            )}
            {footer.body && <RenderHtml html={footer.body} className={classes.copyRights}></RenderHtml>}
          </ContentContainer>
        </footer>
      )}
    />
  );
};

export default Footer;
