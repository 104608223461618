import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  listRoot: {
    padding: '0 10px',
    display: 'block',
    position: 'static',
    top: 'auto',
    left: 'auto',
    maxWidth: 'none',
    transform: 'none',
  },
  listItemRoot: {
    padding: [[0, 0, 0, theme.spacing(4)]],
    float: 'right',
    width: 'auto',
    margin: 0,
    justifyContent: 'flex-start',
  },
  listItemLink: {
    position: 'relative',
    lineHeight: '2rem',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontSize: '1.125rem',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      borderBottom: '0.2rem solid transparent',
      transition: 'border-bottom-color 200ms ease-out',
    },
    '&:hover:after': {
      borderBottomColor: theme.palette.link.primary,
      transition: 'border-bottom-color 600ms ease-in',
    },
  },
}));

const MenuItems = ({ navigationItems }) => {
  const classes = useStyles();

  navigationItems = navigationItems || [];
  const items = [...navigationItems].reverse();

  return (
    <List classes={{ root: classes.listRoot }}>
      {items.map(item => (
        <ListItem key={item.name} component={'li'} classes={{ root: classes.listItemRoot }}>
          <Link to={`${item.url}`} className={classes.listItemLink}>
            {item.name}
          </Link>
        </ListItem>
      ))}
    </List>
  );
};

export default MenuItems;
