import React from 'react';
import cleanupIncomingHtml from './inner-html-parser';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import parse, { domToReact } from 'html-react-parser';

const useStyles = makeStyles(theme => ({
  inlineHtmlWrapper: {
    width: '100%',
    '& h1, & h2, & h3, & h4': {
      margin: '0 0 12px 0',
    },
    '& p': {
      marginBottom: '0.8em',
    },
    '& ul': {
      listStyleType: 'square',
      marginBottom: '0.8em',
    },
    '& ul ul': {
      listStyleType: 'disc',
    },
    '& li': {
      marginBottom: theme.spacing(1),
      lineHeight: '1.5em',
    },
  },
}));

let h1Counter = 0;
let preserveFirstH1 = false;

const replaceWithMuiComponents = ({ type, name, attribs, children }) => {
  if (type !== 'tag') {
    return;
  }

  switch (name) {
    case 'h1':
      const shouldRenderH1tag = h1Counter === 0 && preserveFirstH1;
      const component = (
        <Typography variant='h1' component={shouldRenderH1tag ? 'h1' : 'h2'}>
          {domToReact(children)}
        </Typography>
      );
      h1Counter++;
      return component;
    case 'h2':
      return <Typography variant='h2'>{domToReact(children)}</Typography>;
    case 'h3':
      return <Typography variant='h3'>{domToReact(children)}</Typography>;
    case 'h4':
      return <Typography variant='h4'>{domToReact(children)}</Typography>;
    case 'p':
      return <Typography variant='body1'>{domToReact(children)}</Typography>;
    case 'ul':
      return (
        <Typography variant='body1' component='ul'>
          {domToReact(children)}
        </Typography>
      );
    default:
      return;
  }
};

const RenderGatsbyHtml = ({ className, html, preserveFirstH1tag }) => {
  const classes = useStyles();
  const cleanHtml = cleanupIncomingHtml(html);
  h1Counter = 0;
  preserveFirstH1 = preserveFirstH1tag;

  return (
    <div className={clsx(classes.inlineHtmlWrapper, className)}>
      {parse(cleanHtml, {
        replace: replaceWithMuiComponents,
      })}
    </div>
  );
};
export default RenderGatsbyHtml;
