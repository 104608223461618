import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  linkItem: {
    fontSize: '1.2rem',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
  },
}));

const DrawerItems = ({ navigationItems, toggleDrawer }) => {
  const classes = useStyles();
  const items = [...(navigationItems || [])];

  return (
    <div className={classes.list} role='presentation' onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {items.map(item => (
          <Link to={`${item.url}`} className={classes.linkItem} key={item.name}>
            <ListItem button>
              <ListItemText primary={item.name} classes={{ primary: classes.linkItem }} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
};

export default DrawerItems;
