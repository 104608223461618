import React from 'react';
import Navigation from './_components/navigation';
import Footer from './_components/footer';
import CookieConsentBar from './_components/cookie-consent';
import Container from './_components/container';
import themeProperties from './theme';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import '../../assets/styles/base.css';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const theme = responsiveFontSizes(createMuiTheme(themeProperties));

const useStyles = makeStyles(theme => ({
  content: {
    flex: '1 0 auto',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
}));

const Layout = props => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Navigation />
      <div className={classes.contentContainer}>
        <Container className={clsx([classes.content, className])}>{children}</Container>
        <Footer />
      </div>
      <CookieConsentBar />
    </MuiThemeProvider>
  );
};

export default Layout;
