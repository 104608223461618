import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import Media from '../../../media';
import MenuItems from './_components/menu-items';
import DrawerItems from './_components/drawer-items';

const VerticallyCentered = ({ children }) => (
  <Grid container alignContent='center' style={{ height: '100%' }}>
    <Grid item xs>
      {children}
    </Grid>
  </Grid>
);

const useStyles = makeStyles(theme => ({
  header: {
    top: 0,
    right: 0,
    left: 0,
    zIndex: 3,
    background: fade(theme.palette.background.default, 0.9),
    position: 'fixed',
    display: 'block',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  wrapper: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.contentSizes.mobileMargin,
      marginRight: theme.contentSizes.mobileMargin,
    },
  },
  navContainer: {
    margin: '0 auto',
    height: 99,
    [theme.breakpoints.down('md')]: {
      height: 60,
    },
    [theme.breakpoints.up('lg')]: {
      height: 80,
      width: theme.contentSizes.desktop,
    },
  },
  logoWrapper: {
    '& img': {
      height: 'auto',
      width: 80,
      display: 'block',
      [theme.breakpoints.up('lg')]: {
        width: 100,
      },
      [theme.breakpoints.up('xl')]: {
        width: 120,
      },
    },
  },
  menuWrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    height: 'calc(100% - 60px)',
    width: '100%',
    padding: 0,
    background: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      position: 'static',
      height: 'auto',
      width: 'auto',
      background: 'none',
    },
  },
  btnMenu: {
    float: 'right',
    position: 'relative',
    display: 'block',
    height: 20,
    width: 30,
    marginRight: '5%',
    padding: 0,
    border: '0 none',
    background: 'none',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '&:focus': {
      outline: '0 none',
    },
    '& + div': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    '& span': {
      position: 'absolute',
      display: 'block',
      height: 2,
      width: 30,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: theme.palette.text.primary,
      },
    },
    '& span:nth-child(1)': {
      top: 0,
    },
    '& span:nth-child(2)': {
      top: 10,
    },
    '& span:nth-child(3)': {
      top: 20,
    },
  },
}));

export default () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <StaticQuery
      query={graphql`
        query NavigationQuery {
          ...LiderLogo
          contentfulGlobalSettings(contentful_id: { eq: "5BS8CiVRpPaLRNQgNE5WBb" }) {
            topNavigationItems {
              url
              name
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <header className={classes.header}>
            <div className={classes.wrapper}>
              <Grid container className={classes.navContainer} alignItems='center'>
                <Grid item xs={2} className={classes.logoWrapper}>
                  <Link to='/'>
                    <VerticallyCentered>
                      <Media alt={'Logo LIDER Jasło'} source={data.logo} />
                    </VerticallyCentered>
                  </Link>
                </Grid>
                <Grid item xs>
                  <VerticallyCentered>
                    <nav>
                      <button
                        className={isOpen ? clsx(classes.btnMenu, classes.btnMenuIsOpen) : classes.btnMenu}
                        type='button'
                        title='expand menu'
                        onClick={toggleDrawer(true)}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                      <div className={classes.menuWrapper}>
                        {!isOpen && <MenuItems navigationItems={data.contentfulGlobalSettings.topNavigationItems} />}
                        <Drawer anchor='right' open={isOpen} onClose={toggleDrawer(false)}>
                          <DrawerItems
                            navigationItems={data.contentfulGlobalSettings.topNavigationItems}
                            toggleDrawer={toggleDrawer}
                          />
                        </Drawer>
                      </div>
                    </nav>
                  </VerticallyCentered>
                </Grid>
              </Grid>
            </div>
          </header>
        </React.Fragment>
      )}
    />
  );
};
