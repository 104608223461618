import React from 'react';
import { graphql } from 'gatsby';
import { SocialIcon } from 'react-social-icons';
import { useTheme } from '@material-ui/core/styles';

export const SocialBlockQueryFragment = graphql`
  fragment SocialBlock on ContentfulBlockSocial {
    __typename
    name
    link
    type
  }
`;

export default ({ data, size }) => {
  const theme = useTheme();
  return (
    <SocialIcon
      url={data.link}
      target='_blank'
      rel='noopener noreferrer'
      style={{ width: size, height: size }}
      bgColor={theme.palette.primary.main}
      fgColor={theme.palette.background.default}
    />
  );
};
