import palette from './palette';

const hRelatedProperties = {
  '& a, & a:hover, & a:active, & a:focus': {
    color: palette.link.primary,
    textDecoration: 'none',
  },
  '& strong': {
    color: palette.link.primary,
  },
};

const baseProperties = {
  '& a, & a:hover, & a:active, & a:focus': {
    color: palette.link.primary,
    textDecoration: 'none',
  },
  '& p': {
    margin: 0,
  },
};

export default {
  useNextVariants: true,
  fontFamily: ['Lato', 'Open Sans', 'Arial', 'Helvetica', 'sans-serif'].join(','),
  htmlFontSize: 17,
  h1: {
    fontSize: '2.4rem',
    lineHeight: 1.2,
    fontWeight: '700',
    letterSpacing: 'normal',
    ...baseProperties,
    ...hRelatedProperties,
  },
  h2: {
    fontSize: '1.6rem',
    lineHeight: 1.2,
    fontWeight: 'bold',
    letterSpacing: 'normal',
    ...baseProperties,
    ...hRelatedProperties,
  },
  h3: {
    fontSize: '1.5rem',
    lineHeight: 1.2,
    fontWeight: 'bold',
    letterSpacing: 'normal',
    ...baseProperties,
    ...hRelatedProperties,
  },
  h4: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    ...baseProperties,
    ...hRelatedProperties,
  },
  h5: {
    fontSize: '1.1rem',
    lineHeight: 1.1,
    fontWeight: 'bold',
    ...baseProperties,
    ...hRelatedProperties,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.5,
    ...baseProperties,
  },
};
