import typography from './typography';
import palette from './palette';

const contentSizes = {
  tablet: 984,
  desktop: 1140,
  mobileMargin: 10,
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1090,
    lg: 1400,
    xl: 1840,
  },
};

const theme = {
  breakpoints,
  typography,
  palette,
  contentSizes,
};

export default theme;
