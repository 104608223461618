import get from 'lodash-es/get';

const parse = markdown => {
  const text = get(markdown, 'childMarkdownRemark.html', markdown);
  if (!text || !text.replace) {
    return { __html: '' };
  }

  return text.replace(/\n/g, '\n'); //new line heights are difficult to control; try using CSS: white-space: pre-wrap
};

export default parse;
