const palette = {
  background: {
    default: '#fff',
    alternative: '#f5f5f5',
  },
  text: {
    primary: 'black',
  },
  primary: {
    main: '#d86868',
    dark: '#ce3f3f',
  },
  link: {
    primary: '#d86868',
    secondary: '#fff',
  },
};

export default palette;
