import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.default,
    position: 'relative',
    margin: '0 auto',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: theme.contentSizes.tablet,
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.contentSizes.desktop,
    },
  },
}));

export default ({ className, children }) => {
  const classes = useStyles();
  return <div className={className ? clsx([classes.container, className]) : classes.container}>{children}</div>;
};
