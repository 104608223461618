import React from 'react';
import { makeStyles } from '@material-ui/styles';
import SocialBlock from '../../../../social-block';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  socialSection: {
    position: 'fixed',
    right: 30,
    bottom: 30,
    [theme.breakpoints.down('sm')]: {
      right: 15,
      bottom: 15,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      marginLeft: theme.spacing(3),
      right: 0,
      bottom: 0,
    },
  },
}));

export default ({ buttons }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isHandheld = useMediaQuery(theme.breakpoints.down('xs'));
  const size = isHandheld ? 25 : 50;

  return (
    <div className={classes.socialSection}>
      {buttons.map(button => (
        <SocialBlock data={button} key={button.link} size={size} />
      ))}
    </div>
  );
};
