import React from 'react';
import CookieConsent from 'react-cookie-consent';

export default () => (
  <CookieConsent
    location="bottom"
    buttonText="Wszystko jasne"
    cookieName="cookieConsent"
    buttonStyle={{ background: '#d86868', color: 'white' }}
  >
    Aby świadczyć usługi na najwyższym poziomie niniejsza strona korzysta z
    ciasteczek. Dalsze korzystanie z witryny oznacza zgodę na ich użycie.
  </CookieConsent>
);
