import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.default,
    position: 'relative',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    paddingTop: '100px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.contentSizes.mobileMargin,
      paddingRight: theme.contentSizes.mobileMargin,
    },
  },
}));

export default ({ children, className }) => {
  const classes = useStyles();

  return <main className={clsx([classes.container, className])}>{children}</main>;
};
