import React from 'react';
import Img from 'gatsby-image';
import get from 'lodash-es/get';

const Media = ({ source, alt, className, style, ...mediaProps }) => {
  if (!source) {
    return <></>;
  }

  const type = get(source, 'file.contentType');
  if (type && type.includes('video'))
    return (
      <video
        src={source.file.url}
        alt={alt}
        className={className}
        style={style}
        type={source.file.contentType}
        {...mediaProps}
      />
    );

  if (type && type.includes('svg'))
    return <img src={source.file.url} alt={alt} className={className} style={style} loading='auto' {...mediaProps} />;

  return !!source.fluid ? (
    <Img fluid={source.fluid} alt={alt} className={className} style={style} loading='auto' {...mediaProps} />
  ) : (
    <img src={source.file.url} alt={alt} className={className} style={style} loading='auto' {...mediaProps} />
  );
};

export default Media;
